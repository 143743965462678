<template>
  <div>
    <c-search-box @enter="getList" :isShowSearch="false">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="TBM 목록"
      :columns="grid.columns"
      :data="grid.data"
      :selection="popupParam.type"
      rowKey="tbmRiskId"
      @rowDblclick="rowDblclick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'beforeTbmRiskPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          tbmId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        tbmId: '',
      },
      grid: {
        columns: [
          {
            name: "tbmRisk",
            field: "tbmRisk",
            label: "위험요인",
            align: "left",
            style: "width:40%",
            sortable: false,
          },
          {
            name: "tbmImprove",
            field: "tbmImprove",
            label: "안전대책",
            style: "width:40%",
            align: "left",
            sortable: false,
          },
          {
            name: 'ramAfterRiskLevelName',
            field: 'ramAfterRiskLevelName',
            label: '판단결과',
            style: "width:10%",
            align: 'center',
            sortable: false,
          },
          {
            name: "tbmRiskKeyFlag",
            field: "tbmRiskKeyFlag",
            label: "중점요인",
            style: "width:10%",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.tbm.beforeRisk.url;
      // code setting
      this.searchParam.tbmId = this.popupParam.tbmId
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
